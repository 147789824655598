.main-content {
    min-height: 80vh;
    text-align: center;
    padding: 0% 1vw;
    border: 1px solid blue;
    position: relative;
}

.main-content-logo {
    align-self: center;
    background-size: cover;
    background-image: url("../../assets/images/red-strip.png");
    background-repeat: no-repeat;
    content: '';
    color: whitesmoke;
    display: inline-block;
    width:95%;
    height: 92px;
}


.body-content {
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-section {
    /* border: 1px solid red; */
}

.body-content-header {
    background-color: #b8b8b8;
    font-weight: 600;
    font-size:1em;
    margin: 0.2rem 0 0.2rem 0;
}

.table-div {
    overflow-x: scroll;
    margin-top: 1rem;
}

.error-text {
    color: red;
    font-size: 0.8rem;
}

.card-label {
    margin-bottom: 0.8rem;
}

.errorCard {
    border: 1px solid red;
}