.headerContainer {
    margin-top: 18px;
    font-size: 16px;
    color: black !important;
    font-family: overpass,sans-serif;
    font-weight: 600;
    box-shadow: 0 4px 2px -2px rgb(214, 214, 214);
}

#basic-nav-dropdown {
    color: black !important;
}
#basic-nav-dropdown:hover {
    color: #cf2f44 !important;
}

.navList {
    color: black !important;
    padding-left: 2% !important;
}

.dropdownLink {
    text-decoration: none;
    color: #cf2f44;

}

.dropdownLink {
    text-decoration: none;
    color: #cf2f44;
    font-weight: 300;
}

.dropdown-item {
    color: black;
    font-weight: 700 !important;
    font-family: overpass,sans-serif;
}

.dropdown-item:active {
    background-color: #cf2f44 !important;
    font-weight: 700;
    font-family: overpass,sans-serif;
}

.dropdown-item a:hover {
    color: #cf2f44;
}