.play-buttons {
    margin: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 4px;
    background-color: #d1d0bd;
}


.orders-table {
    min-width: 100%;
}

td {
    /* width: 35ch; */
    /* max-width: 30ch; */
    /* min-width: 30ch; */
    /* max-width: 35ch;
    min-width: 35ch; */
    word-wrap: break-word;
    padding: 10px;
    /* word-break:break-word; */
    font-size:0.7em;
    /* background-color: yellow !important; */
    border: 1px solid black;
}

th {
    min-width: 10ch;
    max-width: 35ch;
    word-wrap: break-word;
}